/* eslint-disable react/prop-types */
import { useLocation, useNavigate } from 'react-router-dom'

export function Link(props) {
  const { to } = props
  const { search } = useLocation()
  const goTo = (id) => {
    const el = document.getElementById(id)
    if (el) {
      window.scrollTo({
        top: el.offsetTop - 100,
        behavior: 'smooth',
      })
    }
  }
  const navigate = useNavigate()
  const handleClick = () => {
    if (to) {
      if (to.includes('#')) {
        goTo(to.substring(to.indexOf('#') + 1))
      } else {
        if (props.to.includes('http')) {
          const a = document.createElement('a')
          a.href = to
          a.target = '_blank'
          a.click()
        } else {
          navigate(props.to + `${search}`)
        }
      }
    }
  }
  return (
    <span
      className={`cursor-pointer ${!props.noUnderline ? 'hover:underline' : ''} ${
        props.className || ''
      }`}
      onClick={handleClick}
    >
      {props.children}
    </span>
  )
}
