import AOS from "aos";
import { React, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Web3TsProvider, bscTestnet } from "web3ts";
import "./assets/bootstrap.min.css";
import "./assets/style.css";
import Header from "./components/header/Header";
import { useData } from "./components/hooks/useData";
import routes from "./pages";
import Page404 from "./pages/404";
function App() {
 useEffect(() => {
  AOS.init({
   duration: 2000,
  });
 }, []);
 const { data: ENV } = useData({ path: "/env.json" });
 if (!ENV) return <></>;
 return (
  <>
   <Header />
   <Web3TsProvider
    config={{
     buyContract: {
      contractAddress: ENV.buyContractAddress,
      buyMethod: "Buy",
      buyUSDTMethod: "BuyWithUSDT",
      referralPath: ENV.referralPath,
      disableBuy: ENV.disableBuy === true,
     },
     mainContract: {
      contractAddress: ENV.contractAddress,
      logo: window.location.origin + "/logo4wallet.png",
      symbol: ENV.tokenSymbol,
     },
     chains: [bscTestnet],
     metadata: {
      url: window.location.origin,
      ...ENV.metadata,
     },
     projectId: "339038604a4b6c8ad4620c7ff918d2a9",
     walletIds: [
      "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
     ],
    }}
   >
    <Routes>
     {routes.map((data, idx) => (
      <Route key={idx} path={data.path} element={data.component} exact />
     ))}

     <Route path="*" element={<Page404 />} />
    </Routes>
    <ToastContainer hideProgressBar position="bottom-center" />
   </Web3TsProvider>
  </>
 );
}

export default App;
