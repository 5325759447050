import React, { useState } from "react";
import { useData } from "web3ts";

function Speciality(props) {
 const { data } = useData({ default: [], path: "/data/dataBox.json" });

 const [dataBlock] = useState({
  subheading: "Prominence",
  heading: "Building 6G technology connected with NFT",
  desc:
   "We will combine the powerful coverage of 6G with NFT products to bring a new experience",
 });
 return (
  <section className="speciality">
   <div className="shape right"></div>
   <div className="container">
    <div className="row">
     <div className="col-12">
      <div className="block-text center">
       <h6 className="sub-heading">
        <span>{dataBlock.subheading}</span>
       </h6>
       <h3 className="heading pd">{dataBlock.heading}</h3>
       <p className="">{dataBlock.desc}</p>
      </div>
     </div>
     {data.map((idx) => (
      <div key={idx.id} className="col-xl-3 col-md-6">
       <div className="speciality-box">
        <div className="icon">
         <img src={idx.img} alt="Thermal" />
        </div>
        <h5 className="title">{idx.title}</h5>
        <p>{idx.desc}</p>
        <h3 className="number">0{idx.id}</h3>
       </div>
      </div>
     ))}
    </div>
   </div>
  </section>
 );
}

export default Speciality;
