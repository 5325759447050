import React from "react";
import { Link } from "react-router-dom";
import { Clock } from "../clock";

function Banner(props) {
 return (
  <section className="banner">
   <div className="shape right"></div>
   <div className="container big">
    <div className="row">
     <div className="col-xl-6 col-md-12">
      <div className="banner__left">
       <div className="block-text">
        <h2 className="heading">
         Changing 6G technology <br />
         with the <span className="s1 arlo_tm_animation_text_word">
          NFTs
         </span>{" "}
         <br />
         trend
        </h2>
        <p className="desc">
         Thermal is a project that uses and develops 6G technology on
         blockchain, we bring a new direction that is more innovative and more
         accessible through 6G.
        </p>
        <Clock />
        <Link to="/buy" className="action-btn mt-10">
         <span>Get RMA</span>
        </Link>
       </div>

       <div className="pay">
        <h6>We create a dynamic NFT community</h6>

        <div className="list">
         <p>Our Partners: </p>

         <ul>
          <li>
           <Link to="#">
            <span className="icon-logo-01"></span>
           </Link>
          </li>
          <li>
           <Link to="#">
            <span className="icon-logo-02"></span>
           </Link>
          </li>
          <li>
           <Link to="#">
            <span className="icon-logo-03"></span>
           </Link>
          </li>
          <li>
           <Link to="#">
            <span className="icon-logo-04"></span>
           </Link>
          </li>
          <li>
           <Link to="#">
            <span className="icon-logo-05">
             <span className="path1"></span>
             <span className="path2"></span>
             <span className="path3"></span>
             <span className="path4"></span>
            </span>
           </Link>
          </li>
          <li>
           <Link to="#">
            <span className="icon-logo-06"></span>
           </Link>
          </li>
         </ul>
        </div>
       </div>
      </div>
     </div>
     <div className="col-xl-6 col-md-12">
      <div className="banner__right">
       <div className="image">
        <img src="/images/layouts/banner.png" alt="thermal" />
       </div>

       <div className="price">
        <div className="icon">
         <img src="/images/logo/logo-only.png" alt="thermal" />
        </div>
        <div className="content">
         <p>Price</p>
         <h5>5.5 BNB</h5>
        </div>
       </div>

       <div className="owner">
        <div className="image">
         <img src="/images/layouts/avt-01.png" alt="thermal" />
        </div>
        <div className="content">
         <h5>Thermal NFT</h5>
         <p>@Thermal_Network</p>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </section>
 );
}

export default Banner;
