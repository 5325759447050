import { useConnect } from "web3ts";

export const ConnectWalletButton = () => {
 const { connect, isConnected } = useConnect();
 if (isConnected) return <></>;
 return (
  <button className="action-btn  mt-10" onClick={connect}>
   <span>Connect wallet</span>
  </button>
 );
};
