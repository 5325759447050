import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { ENV } from "../../env";
import menus from "../../pages/menu";

import "./styles.scss";
const Header = () => {
 const [scroll, setScroll] = useState(false);
 useEffect(() => {
  window.addEventListener("scroll", () => {
   setScroll(window.scrollY > 300);
  });
  return () => {
   setScroll({});
  };
 }, []);

 const [menuActive, setMenuActive] = useState(null);

 const handleMenuActive = () => {
  setMenuActive(!menuActive);
 };

 return (
  <header id="header_main" className={`header ${scroll ? "is-fixed" : ""}`}>
   <div className="container big">
    <div className="row">
     <div className="col-12">
      <div className="header__body">
       <div className="header__logo">
        <Link to="/">
         <img id="site-logo" src="/images/logo/logo.png" alt="thermal" />
        </Link>
       </div>

       <div className="header__right">
        <nav id="main-nav" className={`main-nav ${menuActive ? "active" : ""}`}>
         <ul id="menu-primary-menu" className="menu">
          {menus.map((data, idx) => (
           <NavLink
            to={data.links}
            key={idx}
            className={({ isActive }) =>
             `menu-item ${data.namesub ? "menu-item-has-children" : ""} ${
              isActive ? "active" : ""
             }`
            }
           >
            {data.name}
           </NavLink>
          ))}
          <Link to={ENV.whitepaper} target="_blank" className="menu-item">
           Whitepaper
          </Link>
         </ul>
        </nav>
        <div
         className={`mobile-button ${menuActive ? "active" : ""}`}
         onClick={handleMenuActive}
        >
         <span></span>
        </div>
       </div>

       <div className="header__action">
        <Link to="/buy" className="action-btn">
         <span>Get RMA</span>
        </Link>
       </div>
      </div>
     </div>
    </div>
   </div>
  </header>
 );
};

export default Header;
