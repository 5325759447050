import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { useData } from "web3ts";
import Partner from "../partner/Partner";

function Team(props) {
 const { data } = useData({
  default: [],
  path: "/data/dataTeam.json",
 });
 const [dataBlock] = useState({
  subheading: "Team Members",
  heading: "Our Amazing Team Members",
 });

 return (
  <section className="team">
   <div className="container">
    <div className="row">
     <div className="col-12">
      <div className="block-text center">
       <h6 className="sub-heading">
        <span>{dataBlock.subheading}</span>
       </h6>
       <h3 className="heading pd">{dataBlock.heading}</h3>
      </div>

      <Swiper
       className="team-swiper"
       spaceBetween={30}
       breakpoints={{
        0: {
         slidesPerView: 1,
        },
        500: {
         slidesPerView: 2,
        },
        768: {
         slidesPerView: 3,
        },
        991: {
         slidesPerView: 4,
        },
       }}
       loop={true}
      >
       {data.map((idx) => (
        <SwiperSlide key={idx.id}>
         <div className="swiper-slide">
          <div className="team-box">
           <div className="image">
            <a href="javascript:void(0)">
             <img src={idx.img} alt="Thermal" />
            </a>
           </div>
           <div className="content">
            <a href="javascript:void(0)" className="h5 name">
             {idx.name}
            </a>
            <p className="postion">{idx.position}</p>
           </div>
          </div>
         </div>
        </SwiperSlide>
       ))}
      </Swiper>

      <Partner />
     </div>
    </div>
   </div>
  </section>
 );
}

export default Team;
