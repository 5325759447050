import { ethers } from "ethers";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { myToast, useAccount, useConnect } from "web3ts";
import { ENV } from "../../env";
import { ConnectWalletButton } from "../../pages/Buy/ConnectWalletButton";
import { useNftContract } from "../hooks/nft/useNftContract";
import Pagination from "../pagination/Pagination";

function Inner() {
 const [data, setData] = useState([]);
 const { account } = useAccount();
 const { marketContract, nftContract } = useNftContract();
 useEffect(() => {
  if (marketContract) {
   marketContract.getNfts().then(async (resp) => {
    const _data = [];
    const promises = [];
    for (let nftData of resp) {
     const data = {
      price: Number(nftData.price) / 1e18,
      owner: nftData.owner,
      marketItemId: nftData.marketItemId,
     };
     promises.push(
      fetch(nftData.tokenURI)
       .then((t) => t.json())
       .then((tokenMeta) => {
        Object.assign(data, tokenMeta);
       })
     );
     _data.push(data);
    }
    await Promise.all(promises);
    setData(_data);
   });
  }
 }, [marketContract]);
 const [currentPage, setCurrentPage] = useState(1);
 const [itemsPerPage] = useState(8);

 // Lấy dữ liệu hiện tại
 const currentData = useMemo(() => {
  const indexOfLastItem = currentPage * itemsPerPage,
   indexOfFirstItem = indexOfLastItem - itemsPerPage;
  return data.slice(indexOfFirstItem, indexOfLastItem);
 }, [currentPage, itemsPerPage, data]);

 const buy = async ({ marketItemId, price }) => {
  let _toast;
  let isApprovedForAll = await nftContract.isApprovedForAll(
   account,
   ENV.marketContractAddress
  );
  if (!isApprovedForAll) {
   _toast = myToast.toast("Require Approval For Market Contract");
   try {
    const process = await nftContract.setApprovalForAll(
     ENV.marketContractAddress,
     true
    );
    _toast?.();
    _toast = myToast.waitTransaction();
    await process.wait();
    _toast?.();
   } catch (error) {
    myToast.toast(error.reason, { type: "error" });
   }
   let isApprovedForAll = await nftContract.isApprovedForAll(
    account,
    ENV.marketContractAddress
   );
   if (!isApprovedForAll) {
    return myToast.toast("Please, try again!");
   }
  }
  try {
   const etherValue = ethers.utils.parseEther(price + "").toBigInt();
   const process = await marketContract.buyNFT(marketItemId, {
    value: etherValue,
   });
   _toast = myToast.waitTransaction();
   await process.wait();
   _toast?.();
   myToast.toast("Congratulations! You have successfully purchased the NFT.", {
    type: "success",
   });
   const _nftData = data.find((f) => f.marketItemId === marketItemId);
   if (_nftData) {
    _nftData.owner = account;
    setData([...data]);
   }
  } catch (error) {
   myToast.toast(error.reason, { type: "error" });
  }
 };

 // Thay đổi trang
 const paginate = (pageNumber) => setCurrentPage(pageNumber);
 return (
  <section className="nft">
   <div className="container">
    <div className="row">
     {currentData.map((idx) => (
      <div key={idx.marketItemId} className="col-xl-3 col-md-6">
       <div className="nft-item">
        <div className="card-media">
         <Link to="#">
          <img src={idx.image} alt="Thermal" />
         </Link>
        </div>
        <div className="card-title">
         <Link to="#" className="h5">
          {idx.name}
         </Link>
        </div>
        <div className="meta-info overflow-hidden">
         <div className="author overflow-hidden">
          <div className="avatar">
           <img src="/images/layouts/avt-01.png" alt="Thermal" />
          </div>
          <div className="info  overflow-hidden">
           <span>Owned by</span>
           <Link to="#" className="h6 truncate w-fit" title={idx.owner}>
            {idx.owner}
           </Link>
          </div>
         </div>
        </div>
        <div className="card-bottom style-explode">
         <div className="price">
          <div className="price-details">
           <span> Current Bid</span>
           <h6>{idx.price} BNB</h6>
          </div>
         </div>
         <div className="button-place-bid">
          <a
           href="javascript:void(0)"
           className="sc-button"
           onClick={() => buy(idx)}
          >
           <span>Buy</span>
          </a>
         </div>
        </div>
       </div>
      </div>
     ))}
    </div>
    <Pagination
     itemsPerPage={itemsPerPage}
     totalItems={data.length}
     paginate={paginate}
     currentPage={currentPage}
    />
   </div>
  </section>
 );
}

function ProjectPagination() {
 const { isConnected } = useConnect();
 if (isConnected) return <Inner />;
 return (
  <div className="flex flex-col items-center  py-20">
   <p className="text-center">Please connect your wallet to display NFTs</p>
   <ConnectWalletButton />
  </div>
 );
}

export default ProjectPagination;
