import React from "react";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Link } from "react-router-dom";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { useData } from "web3ts";

function Project(props) {
 const { data } = useData({ default: [], path: "/data/nfts/featured.json" });
 return (
  <section className="project">
   <div className="shape right"></div>
   <div className="container">
    <div className="row">
     <div className="col-12">
      <div className="block-text center">
       <h6 className="sub-heading">
        <span>Our Speciality</span>
       </h6>
       <h3 className="heading">OUR PRODUCT</h3>
      </div>

      <Swiper
       className="project-swiper"
       spaceBetween={30}
       breakpoints={{
        0: {
         slidesPerView: 1,
        },
        768: {
         slidesPerView: 2,
        },
        991: {
         slidesPerView: 3,
        },
       }}
       loop={true}
       modules={[Navigation, Pagination]}
       navigation
       pagination={{
        clickable: true,
       }}
      >
       {data.map((idx) => (
        <SwiperSlide key={idx.marketId}>
         <div className="swiper-slide">
          <div className="project-box">
           <div className="image">
            <Link to="/nfts">
             <img src={idx.image} alt="Thermal" />
            </Link>
           </div>
           <div className="content">
            <Link to="/nfts" className="h5 title">
             {idx.name}
            </Link>
           </div>
          </div>
         </div>
        </SwiperSlide>
       ))}
      </Swiper>
     </div>
    </div>
   </div>
  </section>
 );
}

export default Project;
