import About from "./About";
import AdvisorsBackers from "./AdvisorsBackers";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import { BuyPage } from "./Buy";
import Home01 from "./Home01";
import Nfts from "./Nfts";
import RoadMap from "./RoadMap";
import VisionsMission from "./VisionsMission";

const routes = [
 { path: "/", component: <Home01 /> },

 { path: "/nfts", component: <Nfts /> },
 { path: "/news", component: <Blog /> },
 { path: "/blog-details", component: <BlogDetails /> },
 { path: "/visions-mission", component: <VisionsMission /> },
 { path: "/advisors-backers", component: <AdvisorsBackers /> },
 { path: "/about", component: <About /> },
 { path: "/road-map", component: <RoadMap /> },
 { path: "/buy", component: <BuyPage /> },
];

export default routes;
