import { ethers } from "ethers";
import { useMemo } from "react";
import { useEthersSigner } from "web3ts";
import { ENV } from "../../../env";
import { IMarketContractABI, INFTABI } from "./IMarketContractABI";
export const useNftContract = () => {
 const signer = useEthersSigner();
 const marketContract = useMemo(() => {
  if (signer === undefined) return undefined;
  const marketContract = new ethers.Contract(
   ENV.marketContractAddress,
   IMarketContractABI,
   signer
  );
  return marketContract;
 }, [signer]);
 const nftContract = useMemo(() => {
  if (signer === undefined) return undefined;
  const nftContract = new ethers.Contract(
   ENV.nftContractAddress,
   INFTABI,
   signer
  );
  return nftContract;
 }, [signer]);
 return {
  marketContract,
  nftContract,
 };
};
